import { HighContrastTheme } from "../constants";
import featuresConfig from "../features-config";
import globalConfig from "../global-config";

let ieVersionCache: number;

/**
 * HTML Element type
 */
const enum NodeName {
  Anchor = "A",
  Span = "SPAN",
}

/**
 * isSvgSupported function
 * @returns True if the browser supports SVG, false if not
 */
/* eslint-disable compat/compat */
export const isSvgSupported =
  typeof document !== "undefined" &&
  !!document.createElementNS &&
  !!document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect;
/* eslint-enable compat/compat */

/**
 * Check if CSS Animation is supported
 * @returns True if the browser supports CSS animation, false if not
 */
export const isCssAnimationSupported = (): boolean => {
  const prefixes = ["Webkit", "Moz", "O"];
  const elem = document.createElement("div");

  // Check if browser supports animation without any prefixes. If it does, then animation will be supported without any prefixes.
  let supported = elem.style.animationName !== undefined;

  // If browser does not support non-prefixed animations, iterate over prefixes and check if it's set with the AnimationName property instead.
  if (!supported) {
    const supportedPrefix = prefixes.find(
      // allow any here because the property name is dynamically constructed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prefix: string) => elem.style[`${prefix}AnimationName` as any] !== undefined,
    );

    supported = !!supportedPrefix;
  }

  return supported;
};

export const isEdgeClientBrowser = (): boolean =>
  navigator.userAgent.toLowerCase().indexOf("edgeclient/") > -1;

/**
 * Check if history API is supported on the browser (https://developer.mozilla.org/en-US/docs/Web/API/History_API).
 * This function is used when handling the browser history. We want to ensure that a pop state event (navigating history)
 * is only fired when history is supported.
 * (Inspired from KO function isHistorySupported in BrowserControl.js- line 374)
 * @returns if history API is supported on browser
 */
export const isHistorySupported = (): boolean => {
  if (!globalConfig.instance.isHistorySupported) {
    return false;
  }

  const dummyState = "__history_test";
  let historySupported = false;
  if (window) {
    historySupported =
      window.history &&
      typeof window.history.state !== "undefined" &&
      typeof window.onpopstate !== "undefined";
  }

  if (historySupported) {
    try {
      window.history.replaceState(dummyState, "");
      if (window.history.state !== dummyState) {
        // In Android 4.4, the HTML5 History API exists, but calling into does nothing and throws no exception
        historySupported = false;
      } else if (isEdgeClientBrowser()) {
        historySupported = false;
      }
    } catch (e) {
      // In some hosts (WinZip), the HTML5 History API exists, but calling into it barfs.
      historySupported = false;
    }
  }

  return historySupported;
};

/**
 * Convert rgb or rbga values to hex.
 * @param rgba String equivalent of an rgb or rgba color.
 * @returns Hex equivalent of the value supplied; if a hex value is supplied, it's unchanged.
 * If an empty, null, or improper value is supplied, an empty string is returned.
 */
export const rgbaToHex = (rgba: string) => {
  if (!rgba) {
    return "";
  }

  // If we already have hex, return unchanged.
  if (rgba.toUpperCase().match(/^#(([0-9A-F]{3}){1,2})|([0-9A-F]{8})/)) {
    return rgba;
  }

  // If not rgb or rgba string, return empty string.
  if (!rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)!) {
    return "";
  }

  // Extract rgb/rgba numerical values from string and map to array
  // convert opacity to bits for hex conversion
  // convert decimal to hex (radix 16)
  // drop "NaN" for rgb values (no opacity)
  // add hash and concatenate individual hex values
  return `#${rgba
    .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)!
    .slice(1)
    .map((n: string, i: number) =>
      (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, "0")
        .replace("NaN", ""),
    )
    .join("")}`;
};

/**
 * Get the high contrast theme using CSS media query.
 * IE does not support black-on-white or white-on-black. We assign the dark theme when the contrast is active.
 * @returns High contrast theme
 */
export const getHighContrastThemeUsingCssMediaQuery = () => {
  const head = document.getElementsByTagName("head")[0];
  const headStyle = document.createElement("style");
  headStyle.innerHTML = `
      @media screen and (-ms-high-contrast: active) {
          .high-contrast-detection::before {
              content: "active";
              display: none;
          }
      }
      @media screen and (-ms-high-contrast: black-on-white) {
          .high-contrast-detection::before {
              content: "light";
              display: none;
          }
      }
      @media screen and (-ms-high-contrast: white-on-black) {
          .high-contrast-detection::before {
              content: "dark";
              display: none;
          }
      }
      `;

  head.appendChild(headStyle);

  const div = document.createElement("div");
  div.className = "high-contrast-detection";
  document.body.appendChild(div);

  const computedContent = window.getComputedStyle(div, "::before").content;
  let theme = HighContrastTheme.none;

  if (computedContent === '"dark"') {
    theme = HighContrastTheme.dark;
  } else if (computedContent === '"light"') {
    theme = HighContrastTheme.light;
  }

  document.body.removeChild(div);
  head.removeChild(headStyle);

  return {
    isHighContrastActive: ['"active"', '"dark"', '"light"'].includes(computedContent),
    theme,
  };
};

/**
 * Check if high contrast mode is enabled
 * @returns True if the high contrast mode is enabled, false if not
 */
/* istanbul ignore next */
export const isHighContrast = () => {
  const body = document.getElementsByTagName("body")[0];
  const span = document.createElement("span");
  span.style.borderLeftColor = "red";
  span.style.borderRightColor = "blue";
  span.style.position = "absolute";
  span.style.top = "-999px";
  body.appendChild(span);

  const style = window.getComputedStyle(span);
  let isHighContrastActive = style.borderLeftColor === style.borderRightColor;

  body.removeChild(span);

  // The borders color logic above doesn't work with older browsers, like older versions of IE or EdgeHTML.
  // These browsers are used by WebView 1, which affects icon display in flows like OOBE.
  // So we use this additional check to detect high contrast mode in these browsers.
  const { useHighContrastDetectionMode } = featuresConfig.instance;
  if (useHighContrastDetectionMode && !isHighContrastActive) {
    isHighContrastActive = getHighContrastThemeUsingCssMediaQuery().isHighContrastActive;
  }

  return isHighContrastActive;
};

/**
 * Determine if the high contrast theme is light or dark
 * @returns empty string if high contrast is not enabled; "light" or "dark" based on the Windows high contrast theme inferred from the background color.
 */
export const getHighContrastTheme = (): HighContrastTheme => {
  const { useHighContrastDetectionMode } = featuresConfig.instance;
  let hcTheme = HighContrastTheme.none;

  if (isHighContrast()) {
    const body = document.getElementsByTagName("body")[0];
    const style = window.getComputedStyle(body);
    let bgColor = rgbaToHex(style.backgroundColor.toLowerCase().replace(/ /g, ""));

    if (bgColor) {
      // Windows Aquatic theme uses rgb(32,32,32)/#202020
      // Windows Dusk theme uses rgb(45,50,54)/#2d3236
      // Windows Night sky and older high contrast dark use black
      const darkThemes = ["#000000", "#000", "#202020", "#2d3236"];

      // trim opacity value if present in hex
      bgColor = bgColor.length === 7 ? bgColor : bgColor.slice(0, -2);

      if (darkThemes.includes(bgColor)) {
        hcTheme = HighContrastTheme.dark;
      }

      // Windows Desert theme uses rgb(255,250,239)/#fffaef
      // Windows older default high contrast light uses white
      const lightThemes = ["#ffffff", "#fff", "#fffaef"];

      if (lightThemes.includes(bgColor)) {
        hcTheme = HighContrastTheme.light;
      }
    }
  }

  if (useHighContrastDetectionMode && hcTheme === HighContrastTheme.none) {
    hcTheme = getHighContrastThemeUsingCssMediaQuery().theme;
  }

  return hcTheme;
};

export const isHighContrastDark = (): boolean => {
  const hcTheme = getHighContrastTheme();
  return hcTheme === HighContrastTheme.dark;
};

export const isHighContrastLight = (): boolean => {
  const hcTheme = getHighContrastTheme();
  return hcTheme === HighContrastTheme.light;
};

/**
 * Gets the IE version if the browser is IE
 * @param includeEdge indicates whether to include Edge in the check. Edge refers to both Legacy Edge and Chromium Edge.
 * @returns IE version if on IE, else returns -1
 */
export const getIEVersion = (includeEdge: boolean = true): number => {
  // http://codepen.io/gapcode/pen/vEJNZN/
  const ua = typeof window !== "undefined" ? window.navigator.userAgent : "";

  // Example string: Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.1; WOW64; Trident/6.0)
  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  // Example string: Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; AS; rv:11.0) like Gecko
  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  if (includeEdge) {
    // Reference on the Edge-specific tokens in the user agent string:
    // https://learn.microsoft.com.office.lab.mvcuce.myshn.net/en-us/microsoft-edge/web-platform/user-agent-guidance#user-agent-strings
    // Example string: Mozilla/5.0 Chrome/107.0.0.0 Mobile Safari/537.36 Edge/40.15254.603
    const legacyEdge = ua.indexOf("Edge/");
    if (legacyEdge > 0) {
      // IE 12 => return version number
      return parseInt(ua.substring(legacyEdge + 5, ua.indexOf(".", legacyEdge)), 10);
    }

    // Example string: Mozilla/5.0 Chrome/115.0.0.0 Safari/537.36 Edg/115.0.1901.183
    const edge = ua.indexOf("Edg/");
    if (edge > 0) {
      // IE 12 => return version number
      return parseInt(ua.substring(edge + 4, ua.indexOf(".", edge)), 10);
    }
  }

  return -1;
};

/**
 * Check browser settings to see if fido is supported
 * @param isFidoSupportedHint fallback value from server if client cannot decide whether fido is supported
 * @param skipStandardSupportCheck boolean to prevent "isUserVerifyingPlatformAuthenticatorAvailable" check. This is done for Android webview and clients with custom android protocol
 * @returns flag that indicates whether fido is supported
 */
export const getFidoSupport = (isFidoSupportedHint: boolean, skipStandardSupportCheck: boolean) => {
  if (!skipStandardSupportCheck) {
    let windowCredentials;
    let publicKeyCredential;

    if (window.navigator.credentials) {
      windowCredentials = window.navigator.credentials;
    }

    if (window.PublicKeyCredential) {
      publicKeyCredential = window.PublicKeyCredential;
    }

    const supportsStandard =
      windowCredentials !== undefined &&
      windowCredentials.create !== undefined &&
      windowCredentials.get !== undefined &&
      publicKeyCredential !== undefined &&
      publicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== undefined;

    if (!supportsStandard) {
      return false;
    }
  }

  // only standard API is supported, so let server decide if FIDO2 is supported or not
  return isFidoSupportedHint;
};

/**
 * @param skipStandardSupportCheck boolean to prevent "isUserVerifyingPlatformAuthenticatorAvailable" check. This is done for Android webview and clients with custom android protocol.
 * @returns flag that indicates whether user's platform has the necessary authenticator
 */
export const isPlatformAuthenticatorAvailable = async (
  skipStandardSupportCheck: boolean,
): Promise<boolean> => {
  if (skipStandardSupportCheck) {
    return Promise.resolve(true);
  }

  if (window.PublicKeyCredential) {
    return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().catch(
      () =>
        // Ignore error
        false,
    );
  }

  return false;
};

/**
 * @param version the IE version threshold to check for
 * @returns flag that indicates whether the IE version is newer than or the same version as the input value if the browser is IE.
 */
export const isIENewerThan = (version: number): boolean => {
  if (ieVersionCache === undefined) {
    ieVersionCache = getIEVersion();
  }

  return ieVersionCache !== -1 && ieVersionCache >= version;
};

/**
 * @returns flag that indicates whether the browser version is IE10 or newer. This informs us if certain features are available such as
 *     - changing the `type` attribute of input elements
 *     - CSS pseudo-elements like ::-ms-reveal, ::-ms-check etc.
 */
export const isIENewerThan10 = (): boolean => isIENewerThan(10);

const getHTMLElementAncestor = (
  element: HTMLElement | null,
  condition: (element: HTMLElement) => boolean,
): HTMLElement | null => {
  if (!element) {
    return null;
  }

  if (condition(element)) {
    return element;
  }

  return getHTMLElementAncestor(element.parentElement, condition);
};

const isElementDisabledHiddenOrNotDisplayed = (element: HTMLElement): boolean =>
  element.hasAttribute("disabled") ||
  !!element.getAttribute("aria-hidden") ||
  element.style.display === "none";

const isElementOrAncestorDisabledHiddenOrNotDisplayed = (element: HTMLElement): boolean =>
  !!getHTMLElementAncestor(element, isElementDisabledHiddenOrNotDisplayed);

/**
 * Gets keyboard-focusable elements within a specified element
 * Stolen and modified from: https://zellwk.com/blog/keyboard-focusable-elements/
 * @param {HTMLElement} element - element to search within
 * @returns {HTMLElement[]} keyboard-focusable elements
 */
export const getKeyboardFocusableElements = function getKeyboardFocusableElements(
  element: HTMLElement,
) {
  const focusableElements = element.querySelectorAll(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
  );
  const filteredElements: HTMLElement[] = [];
  focusableElements.forEach((el) => {
    const htmlElement = el as HTMLElement;
    if (!isElementOrAncestorDisabledHiddenOrNotDisplayed(htmlElement)) {
      filteredElements.push(htmlElement);
    }
  });
  return filteredElements;
};

/**
 * Sets default focus on the view when there are no form elements like textbox, buttons, radio buttons etc.
 * @param {HTMLElement} rootEleRef - Reference to root element to find child elements nested inside it.
 */
export const setDefaultFocus = function setDefaultFocus(rootEleRef: HTMLElement | null) {
  if (rootEleRef == null) {
    return;
  }

  let isNonTrivialFocusableElementPresent = false;
  let elementToFocus: HTMLElement | null = null;

  const elements = getKeyboardFocusableElements(rootEleRef);

  for (let i = 0; i < elements.length; i += 1) {
    const currentElement: HTMLElement = elements[i];

    if (currentElement.nodeName !== NodeName.Anchor && currentElement.nodeName !== NodeName.Span) {
      // If there are form fields like button, textbox etc., default focus will be on them. So we break out of the loop.
      isNonTrivialFocusableElementPresent = true;
      break;
    } else if (
      !elementToFocus &&
      (currentElement.nodeName === NodeName.Anchor || currentElement.nodeName === NodeName.Span)
    ) {
      elementToFocus = currentElement;
    }
  }

  if (!isNonTrivialFocusableElementPresent && elementToFocus) {
    elementToFocus?.focus();
  }
};

/**
 * Gets the Windows version
 * @returns Windows version if on Windows, else returns -1
 */
export const getWindowsVersion = () => {
  const ua = typeof window !== "undefined" ? window.navigator.userAgent : "";
  const regexPattern = /Windows NT ([0-9]{1,}[.0-9]{0,})/;
  const regexResult = regexPattern.exec(ua);

  // e.g., ["Windows NT 10.0", "10.0"]
  if (regexResult && regexResult.length > 1) {
    return parseFloat(regexResult[1]);
  }

  return -1;
};

/**
 * Indicates if the browser is Edge (Legacy EdgeHTML and Chromium-based Edge)
 * @returns True if the browser is Edge, false if not
 */
export const isEdge = (): boolean => {
  let isEdgeBrowser = false;
  const windowsVersion = getWindowsVersion();

  if (windowsVersion >= 10.0) {
    const ieVersion = getIEVersion();
    isEdgeBrowser = ieVersion >= 12;
  }

  return isEdgeBrowser;
};

/**
 * Indicates if the browser is Chromium-based Edge
 * @returns True if the browser is Chromium Edge, false if not
 */
export const isChromiumEdge = () => {
  const ua = window.navigator;
  return ua.userAgent.indexOf("Edg/") > -1;
};

/**
 * Checks browser to indicate if caps lock warning should be suppressed
 * @returns True if caps lock warning should be suppressed, false if not
 */
export const shouldSuppressCapsLockWarning = () =>
  getIEVersion(false) >= 8 || (!isChromiumEdge() && isEdge());
